'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

/**
 * This solution is adapted from the "Preline UI Javascript loader":
 * https://preline.co/docs/frameworks-nextjs.html.
 * The linked solution was causing console errors and maybe the cause
 *  multiple rehydrations when a single page load occurred.
 * On some iterations, window.HSStaticMethods was unavailable, cause CookieBot
 * instances to visibly disappear when no user interaction occurred.
 *
 * This PrelineScript component handles the initialisation of Preline UI components.
 * This component should be imported and rendered once at the app level.
 *
 * The component uses useRef to track initialisation state across re-renders and
 * useEffect to handle the initialisation logic when the component mounts or
 * when the path changes.
 */
export default function PrelineScript() {
  // Track current path for navigation changes
  const path = usePathname();

  // useRef creates a mutable reference that persists across re-renders
  // initialised.current will be false initially and can be updated without causing re-renders
  const initialised = useRef(false);

  useEffect(() => {
    /**
     * Asynchronously loads and initialises Preline.
     * This function is called when the component mounts and when the path changes.
     */
    const loadPreline = async () => {
      // Skip initialisation if already done to prevent duplicate initialisation
      if (initialised.current) {
        return;
      }

      try {
        // Dynamically import the Preline package
        await import('preline');
      } catch (error) {
        // Log any errors during Preline package loading
        console.error('Error loading Preline:', error);
        initialised.current = false;
      }
    };

    // Call loadPreline when the effect runs
    loadPreline();

    // Cleanup function runs when component unmounts or path changes
    return () => {
      // Only reset the initialised flag if there's a path
      // This prevents unnecessary reinitialisation during initial hydration
      if (path) {
        initialised.current = false;
      }
    };
  }, [path]); // Re-run effect when path changes

  // Component doesn't render anything
  return null;
}
