'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import useConsent from '@/lib/hooks/useConsent';
import Script from 'next/script';

export default function HubspotTracking() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const query = searchParams.toString();
  const consent = useConsent();
  const hasConsented = consent?.marketing ?? false;

  /*
    Track page views without reloading the page. This component expects the following order:

    1. Consent is given
    2. Identify user with email address if available (docs state this is only used for subsequent page views so need to do this before tracking)
    3. Track page view for page load
    4. Load the HubSpot tracking script (doing this before 3 will result in multiple page views)
    5. Track page views for subsequent navigation events

    https://developers.hubspot.com/docs/reference/api/analytics-and-events/tracking-code#tracking-in-single-page-applications
  */
  useEffect(() => {
    // Bail if the userj hasn't consent yet
    if (!hasConsented) return;

    const _hsq = (window._hsq = window._hsq || []);
    console.log('Tracking page view:', `${pathname}${query ? `?${query}` : ''}`);
    _hsq.push(['setPath', `${pathname}${query ? `?${query}` : ''}`]);
    _hsq.push(['trackPageView']);

    // Let <HubspotEmbed /> handle identifying user with email address
  }, [pathname, query, hasConsented]);

  return hasConsented ? (
    <Script
      id="hs-script-loader"
      async
      defer
      src="https://js-eu1.hs-scripts.com/24983590.js"
      onLoad={() => {
        console.log('HubSpot tracking script loaded');
      }}
    />
  ) : null;
}
